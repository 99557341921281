<template>
    <div>
        <CCard>
            <CCardBody>
                <CRow>
                    <CCol sm="5">
                        <h4 id="traffic" class="card-title mb-0">{{ $t('Traffic') }}</h4>
                        <div class="small text-muted text-capitalize">{{ getMonth(10) }}</div>
                    </CCol>
                    <CCol sm="7" class="d-none d-md-block">
                        <CButton color="primary" class="float-right">
                            <i class="fal fa-cloud-upload" />
                        </CButton>
                        <CButtonGroup class="float-right mr-3">
                            <CButton color="outline-secondary" v-for="(label, value) in periodOptions"
                                :key="value + selected" class="mx-0" :pressed="value === selected"
                                @click="selected = value">
                                {{ label }}
                            </CButton>
                        </CButtonGroup>
                    </CCol>
                </CRow>
            </CCardBody>
            <CCardFooter>
                <CRow class="text-center">
                    <CCol md sm="12" class="mb-sm-2 mb-0">
                        <div class="text-muted">{{ $t('Visits') }}</div>
                        <strong>{{ getNum() }} {{ $t('Users') }} ({{ getPercentage() }})</strong>
                        <CProgress class="progress-xs mt-2" :precision="1" color="success"
                            :value="getPercentage({ asInt: true })" />
                    </CCol>
                    <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
                        <div class="text-muted">{{ $t('Unique') }}</div>
                        <strong>{{ getNum() }} {{ $t('Users') }} ({{ getPercentage() }})</strong>
                        <CProgress class="progress-xs mt-2" :precision="1" color="info"
                            :value="getPercentage({ asInt: true })" />
                    </CCol>
                    <CCol md sm="12" class="mb-sm-2 mb-0">
                        <div class="text-muted">{{ $t('Pageviews') }}</div>
                        <strong>{{ getNum() }} {{ $t('Users') }} ({{ getPercentage() }})</strong>
                        <CProgress class="progress-xs mt-2" :precision="1" color="warning"
                            :value="getPercentage({ asInt: true })" />
                    </CCol>
                    <CCol md sm="12" class="mb-sm-2 mb-0">
                        <div class="text-muted">{{ $t('New Users') }}</div>
                        <strong>22.123 {{ $t('Users') }} (80%)</strong>
                        <CProgress class="progress-xs mt-2" :precision="1" color="danger"
                            :value="getPercentage({ asInt: true })" />
                    </CCol>
                    <CCol md sm="12" class="mb-sm-2 mb-0 d-md-down-none">
                        <div class="text-muted">{{ $t('Bounce Rate') }}</div>
                        <strong>{{ $t('Average Rate') }} (40.15%)</strong>
                        <CProgress class="progress-xs mt-2" :precision="1" :value="getPercentage({ asInt: true })" />
                    </CCol>
                </CRow>
            </CCardFooter>
        </CCard>
        <CRow>
            <CCol md="12">
                <CCard>
                    <CCardHeader>
                        {{ $t('Traffic & Sales') }}
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" lg="6">
                                <CRow>
                                    <CCol sm="6">
                                        <CCallout color="info">
                                            <small class="text-muted">{{ $t('New Clients') }}</small><br>
                                            <strong class="h4">{{ getNum() }}</strong>
                                        </CCallout>
                                    </CCol>
                                    <CCol sm="6">
                                        <CCallout color="danger">
                                            <small class="text-muted">{{ $t('Recurring Clients') }}</small><br>
                                            <strong class="h4">{{ getNum() }}</strong>
                                        </CCallout>
                                    </CCol>
                                </CRow>
                                <hr class="mt-0 mb-3">
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(1) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" color="info" :value="getPercentage({ asInt: true })" />
                                        <CProgress class="progress-xs" color="danger"
                                            :value="getPercentage({ asInt: true })" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(2) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(3) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(4) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(5) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(6) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="progress-group mb-4">
                                    <div class="progress-group-prepend">
                                        <span class="progress-group-text text-capitalize">
                                            {{ getWeekday(7) }}
                                        </span>
                                    </div>
                                    <div class="progress-group-bars">
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })" color="info" />
                                        <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                            color="danger" />
                                    </div>
                                </div>
                                <div class="legend text-center">
                                    <small>
                                        <sup>
                                            <CBadge shape="pill" color="info">&nbsp;</CBadge>
                                        </sup>
                                        {{ $t('New Clients') }}
                                        <sup class="ml-2">
                                            <CBadge shape="pill" color="danger">&nbsp;</CBadge>
                                        </sup>
                                        {{ $t('Recurring Clients') }}
                                    </small>
                                </div>
                            </CCol>
                            <CCol sm="12" lg="6">
                                <CRow>
                                    <CCol sm="6">
                                        <CCallout color="warning">
                                            <small class="text-muted">{{ $t('Pageviews') }}</small><br>
                                            <strong class="h4">{{ getNum() }}</strong>
                                        </CCallout>
                                    </CCol>
                                    <CCol sm="6">
                                        <CCallout color="success">
                                            <small class="text-muted">{{ $t('Organic') }}</small><br>
                                            <strong class="h4">{{ getNum() }}</strong>
                                        </CCallout>
                                    </CCol>
                                </CRow>
                                <hr class="mt-0 mb-3">
                                <ul class="horizontal-bars type-2">
                                    <div class="progress-group">
                                        <div class="progress-group-header">
                                            <i class="fas fa-male progress-group-icon" />
                                            <span class="title">{{ $t('Male') }}</span>
                                            <span class="ml-auto font-weight-bold">{{ getPercentage() }}</span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                                color="warning" />
                                        </div>
                                    </div>
                                    <div class="progress-group mb-5">
                                        <div class="progress-group-header">
                                            <i class="fas fa-female progress-group-icon" />
                                            <span class="title">{{ $t('Female') }}</span>
                                            <span class="ml-auto font-weight-bold">{{ getPercentage() }}</span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                                color="warning" />
                                        </div>
                                    </div>
                                    <div class="progress-group">
                                        <div class="progress-group-header">
                                            <i class="fas fa-globe progress-group-icon" />
                                            <span class="title">{{ $t('Organic Search') }}</span>
                                            <span class="ml-auto font-weight-bold">
                                                191,235 <span class="text-muted small">(56%)</span>
                                            </span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="getPercentage({ asInt: true })"
                                                color="success" />
                                        </div>
                                    </div>
                                    <div class="progress-group">
                                        <div class="progress-group-header">
                                            <i class="fab fa-facebook progress-group-icon" />
                                            <span class="title">Facebook</span>
                                            <span class="ml-auto font-weight-bold">
                                                51,223 <span class="text-muted small">(15%)</span>
                                            </span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="15" color="success" />
                                        </div>
                                    </div>
                                    <div class="progress-group">
                                        <div class="progress-group-header">
                                            <i class="fab fa-twitter progress-group-icon" />
                                            <span class="title">Twitter</span>
                                            <span class="ml-auto font-weight-bold">
                                                37,564 <span class="text-muted small">(11%)</span>
                                            </span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="11" color="success" />
                                        </div>
                                    </div>
                                    <div class="progress-group">
                                        <div class="progress-group-header">
                                            <i class="fab fa-linkedin progress-group-icon" />
                                            <span class="title">LinkedIn</span>
                                            <span class="ml-auto font-weight-bold">
                                                27,319 <span class="text-muted small">&nbsp;(8%)</span>
                                            </span>
                                        </div>
                                        <div class="progress-group-bars">
                                            <CProgress class="progress-xs" :value="8" color="success" />
                                        </div>
                                    </div>
                                    <div class="divider text-center">
                                        <CButton color="link" size="sm" class="text-muted">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </CButton>
                                    </div>
                                </ul>
                            </CCol>
                        </CRow>
                        <br />
                        <tableComponent :data="paymentsData" :columns="columns" :filterable="false"
                            :sort-exclude-columns="columns" :show-top-number-of-records="false">
                            <template #avatar="{ row }" class="text-center">
                                <div class="c-avatar">
                                    <img :src="row.avatar.url" class="c-avatar-img" alt="">
                                    <span class="c-avatar-status" :class="`bg-${row.avatar.status || 'secondary'}`"></span>
                                </div>
                            </template>
                            <template #user="{ row }">
                                <div>{{ row.user.name }}</div>
                                <div class="small text-muted">
                                    <span>
                                        <template v-if="row.user.new">{{ $t('New') }}</template>
                                        <template v-else>{{ $t('Recurring') }}</template>
                                    </span>
                                    | {{ $t('Registered') }}: {{ row.user.registered }}
                                </div>
                            </template>
                            <template #usage="{ row }">
                                <div class="clearfix">
                                    <div class="float-left">
                                        <strong>{{ row.usage.value }}%</strong>
                                    </div>
                                    <div class="float-right">
                                        <small class="text-muted">{{ row.usage.period }}</small>
                                    </div>
                                </div>
                                <CProgress class="progress-xs" v-model="row.usage.value" :color="color(row.usage.value)" />
                            </template>
                            <template #payment="{ row }">
                                <i class="fab fa-2x" :class="row.payment.icon" />
                            </template>
                            <template #activity="{ row }">
                                <div class="small text-muted">{{ $t('Last login') }}</div>
                                <strong>{{ row.activity }}</strong>
                            </template>
                        </tableComponent>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import moment from "moment";
import { API_ENDPOINTS } from '@/constants/common';
import { request } from "@/utils/request";

export default {
    name: 'Dashboard',
    components: {},
    data() {
        return {
            API_ENDPOINTS,
            selected: 'Month',
            baseNum: 9.703,
            periodOptions: {
                Day: this.$t('Day'),
                Month: this.$t('Month'),
                Year: this.$t('Year'),
            },
            columns: ['user', 'usage', 'payment', 'activity'],
            paymentsData: [],
        }
    },
    async mounted() {
        this.getStatistics();
        await this.fetchPaymentsData();
    },
    methods: {
        async fetchPaymentsData() {
            const { data } = await request({
                url: API_ENDPOINTS.dashboard,
            });
            this.paymentsData = data;
        },
        getWeekday: moment.weekdays,
        getMonth: moment.months,
        async getStatistics() {
            const { data } = await this.$request({
                url: `${API_ENDPOINTS.dashboard}statistics?period=${this.selected}`,
            });
            this.baseNum = data
        },
        getNum({ baseNum = 9.703 } = {}) {
            if (this.selected === "Day") {
                return baseNum
            }
            if (this.selected === "Month") {
                return baseNum * 30
            }
            if (this.selected === "Year") {
                return baseNum * 365
            }
        },
        getPercentage({ asInt = false } = {}) {
            if (this.selected === "Day") {
                return asInt ? 10 : "10 %"
            }
            if (this.selected === "Month") {
                return asInt ? 20 : "20 %"
            }
            if (this.selected === "Year") {
                return asInt ? 40 : "40 %"
            }
        },
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        }
    }
}
</script>
